.otherProduct_global-container-bg {
    background-image: url('../../assets/img/otherProduct-bg.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 700px;
    display: flex;
    justify-content: center;
}

.otherProduct_global-container{
    max-width: 1350px;
    display: flex;
    gap: 50px;
}

.otherProduct-item-container{
    height: 350px;
    width: 350px;
    padding: 25px;
    border-radius: 8px;
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
}

.otherProduct-item-title{
    text-transform: uppercase;
    text-align: center;
    font-size: 22px;
}

.otherProduct-item-subtitle{
    margin-bottom: 30px;
}

@media (max-width: 1350px) {
    .otherProduct_global-container{
        gap: 20px;
    }

    .otherProduct-item-container{
        width: 320px;
        height: 320px;
    }
}

@media (max-width: 1200px) {
    .otherProduct-item-text{
        font-size: 14px;
    }

    .otherProduct-item-container{
        width: 280px;
        padding: 10px;
    }

    .otherProduct-item-subtitle{
        font-size: 14px;
    }
}

@media (max-width: 1050px) {
    .otherProduct_global-container-bg{
        height: 850px;
    }
}

@media (max-width: 715px) {
    .otherProduct_global-container-bg{
       padding-bottom: 30px;
    }
}

@media (max-width: 340px) {
    .otherProduct-item-container{
        width: 250px;
    }
}

.otherProduct_content-container--mobile{
    display: flex;
    gap: 50px;
}

.otherProduct_global-container--mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel{
    flex-direction: column;
    align-items: center;
    gap:0px
}

.carousel-navigation{
    display: flex;
    margin-top: 10px;
    gap: 15px
}