.technoBannerFull-container{
    width: 100%;
    height: 450px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 10px 0px 10px 0px;
}

.technobanner-title{
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
    color: white;
    font-weight: 200;
    margin-bottom: 30px;
    font-size: 27px;
}

.technobanner-subtitle{
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 20px;
}

.technobanner-subtitle2{
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
}

@media (max-width: 715px) {
    .technoBannerFull-container{
        width: 100%;
    }
}

@media (max-width: 550px) {
    .technobanner-title{
        font-size: 22px;
    }
}

@media (max-width: 500px) {

    .technobanner-title{
        font-size: 18px;
    }

    .technobanner-subtitle{
        font-size: 16px;
    }
}

@media (max-width: 400px) {

    .technobanner-title{
        font-size: 16px;
    }

    .technobanner-subtitle{
        font-size: 16px;
    }

    .technobanner-subtitle2{
        font-size: 14px;
    }
}

@media (max-width: 330px) {
    .technoBannerFull-container{
        height: 500px;
    }

}

