/* Conteneur global */
.timeline_global-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 250px;
    width: 100%;
}

/* Div avec l'image et la ligne verticale */
.timeline-img-line-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Ligne verticale */
.timeline-line {
    width: 0.5px;
    height: 150px;
    background-color: black;
}

/* Conteneur pour le texte et le titre */
.timeline-title-text-container {
    position: absolute;
    top: 42%;
    padding-left: 50px;
    padding-right: 50px;
    transform: translateY(-50%);
    min-width: 350px;
}

.timeline-title-text-container.left {
    left: 10%;
    text-align: left;
}

.timeline-title-text-container.right {
    right: 15%;
    text-align: right;
}

/* Styles pour le titre */
.timeline-title {
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 30px;
}

/* Styles pour le texte */
.timeline-text {
    font-size: 14px;
    text-align: left;
}

.isLast {
    top: 24%;
}

.timeline-text-line {
    display: flex;
    align-items: top;
    gap: 8px;
}

.timeline-icon {
    font-size: 15px;
}

@media (max-width: 860px) {
    .timeline-mobile{
        background-color: rgb(243, 243, 243);
        width: 300px;
        height: 500px;
        border-radius: 8px;
    }
}