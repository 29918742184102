.trumpCard_container{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 440px;
    width: 400px;
    border-radius: 5px;
    background-color: #F8F8F8;
    padding: 30px 20px 30px 20px;
}

.trumpCard_img{
    height: 100px;
}

.trumpCard_title{
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 35px;
    text-align: center;
    font-size: 25px;
    color: #FF7F50;
}

.trumpCard_text{
    text-align: center;
    padding-top: 35px;
    font-size: 18px;
    line-height: 25px;
}

@media (max-width: 1050px) {
    .trumpCard_container{
        width: 375px;
        height: 480px;
    }
}

@media (max-width: 530px) {
    .trumpCard_container{
        height: 450px;
        width: 260px;
    }

    .trumpCard_title{
        font-size: 20px;
    }
}

@media (max-width: 420px) {
    .trumpCard_container{
        height: 450px;
        width: 240px;
    }

    .trumpCard_text{
        font-size: 16px;
    }

}

@media (max-width: 380px) {
    .trumpCard_container{
        height: 430px;
        width: 230px;
    }

    .trumpCard_title{
        font-size: 18px;
    }

    .trumpCard_text{
        font-size: 15px;
    }
}



.trumpCard_text p {
    text-align: center;
    padding-top: 15px;
    font-size: 18px;
    line-height: 25px;
    margin: 0;
}

.trumpCard_text strong {
    font-weight: bold;
    color: #000;
}
