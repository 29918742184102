.header_main_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 40px;
    background-color: black;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.header_box {
    position: fixed; /* Fixe l'élément à la position définie, même en scrollant */
    top: 50px; /* Ajuste l'écart par rapport au haut */
    left: 50%;
    transform: translateX(-50%); /* Centre l'élément horizontalement */
    background-color: #FFFFFF;
    padding: 20px; 
    border-radius: 8px; 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); 
    text-align: center; 
    z-index: 1000; /* Valeur haute pour s'assurer qu'il est au-dessus des autres éléments */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 1380px;
}

.header_box.scrolled {
    top: 15px; /* Réduction de l'espace avec le haut de l'écran */
}


.header_box_div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_box_p{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    width: 186px;
    margin-left: 40px;
    margin-right: 40px;
    cursor: pointer;
}

.header_box_button_p,.header_box_button_p2{
    font-family: 'Montserrat', sans-serif;
    font-size: 21px;
    font-weight: 700;
    margin: 10px;
    cursor: pointer;
}

.header_box_button_p{
    color: #FF7F50;
}
.header_box_button_p2{
    color: #FFFFFF;

} 
.header_box_button,.header_box_button2{
    border-radius: 6px;
}

.header_box_button{
    background-color: #FFFFFF;
    border: 3px solid #FF7F50;
    margin-right: 10px;
}
.header_box_button2{
    background-color: #FF7F50;
    border: 3px solid #FF7F50;

} 

.header_box_button:hover{
    background-color: #FF7F50;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.header_box_button:hover .header_box_button_p {
    color: #FFFFFF;
}

.header_box_button2:hover{
    background-color: #CF572A;
    border: 3px solid #CF572A;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.header_box_button2:hover .header_box_button_p2 {
    color: #ffffff;
}

.header_center {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
}

.header_content {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header_icon {
    width: 20px;
}

.icon_bolder {
    width: 25px;
}

.header_text {
    margin: 0;
    font-size: 14px;
}

.header_language {
    position: absolute;
    right: 15vw;
    top: 13px;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.header_logo {
    background-image: url('../../assets/icons/header_logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 340px;
    height: 60px;
    cursor: pointer;
}

.header_box_button:hover {
    background-color: #ff7f50;
    color: white;
}


/* Container pour le menu */
.dropdown_menu {
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 6px;
    z-index: 1000;
    width: 250px;
}

.menu_section {
    margin-bottom: 20px;
}

.menu_section h3 {
    margin: 0 0 10px 0;
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

/* Liste */
.menu_section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu_section ul li {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
    cursor: pointer;
}

.menu_section ul li:hover {
    color: #000;
    text-decoration: underline;
}

/* Supérieur à 1920px */
@media (min-width: 1921px) {
    .header_language{
        right: 25vw;
    }
}

/* Inférieur a 1275px */
@media (max-width: 1380px) {
    .header_box_button_p, .header_box_button_p2{
        font-size: 17px;
    }
}

@media (max-width: 1245px) {
    .header_logo {
        background-image: url('../../assets/icons/header_logo_small.svg');
        width: 150px;
        height: 60px;
        margin-left: 25px;
    }
}

/* VERSION MOBILE & TABLETTE A FAIRE (NEXT MEDIA A 1050)*/

@media (max-width: 1051px) {
    .header_logo{
        height: 45px;
    }

    .header_box{
        top: 45px;
        height: 45px;
        justify-content: center;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .menu-toggle {
        font-size: 35px;
        width: 60px;
        height: 60px; 
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        position: absolute;
        right: 20px;
    }
    
}

.mobile_menu {
    position: absolute;
    top: 70px;
    right: 0px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
    z-index: 1000;
    font-family: 'Montserrat', sans-serif;
}

.mobile_menu h3 {
    margin: 0 0 10px;
    font-size: 18px;
    color: #333;
    text-transform: uppercase;
}

.mobile_menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 20px;
}

.mobile_menu ul li {
    margin: 5px 0;
    font-size: 16px;
    color: #555;
    cursor: pointer;
}

.mobile_menu ul li:hover {
    color: #000;
}
