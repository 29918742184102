.liposome_global-container{
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    font-family: 'Montserrat', sans-serif;
}

.liposome_intro-container{
    padding-bottom: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.liposome_intro-title{
    text-transform: uppercase;
    font-size: 37px;
    padding-bottom: 30px;
}

.font-42{
    font-size: 42px;
}

.liposome_intro-subtitle{
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 50px;
}

.liposome_forme-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.liposome_forme-title{
    text-transform: uppercase;
    font-size: 33px;
}

.liposome_forme-subtitle{
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 30px;
}

.liposome_forme-icon-item-container{
    display: flex;
    align-items: center;
    gap: 15px;
}

.liposome_forme-icon-container{
    display: flex;
    gap: 50px;
}

.liposome_forme-text{
    font-weight: 500;
    text-transform: uppercase;
    color: #FF7F50;
}

.liposome_forme-icon{
    height: 45px;
    width: 45px;
}

.liposome_forme-description-text{
    text-align: center;
    margin-top: 30px;
}

.liposome_forme-categori-container{
    display: flex;
    gap: 80px;
    margin-top: 50px;
}

.liposome_forme-categori-title{
    text-align: center;
    text-transform: uppercase;
}

.liposome_forme-categori-text{
    text-align: center;
    margin-top: 10px;
}

.liposome_avantage-container{
    display: flex;
    margin-top: 85px;
    gap: 100px;
}

.liposome_avantage-title{
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 40px;
}

.liposome_avantage_rightImg-container{
    background-color: #EBEBE9;
    border-radius: 8px;
    margin-bottom: 20px;
}

.liposome_avantage-description{
    margin-bottom: 20px;
}

.liposome_avantage-buletPoint-title{
    color: #FF7F50;
    min-width: 300px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.liposome_avantage-buletPoint-text{
    margin-bottom: 20px;
}

.liposome_choice-title{
    text-align: center;
    text-transform: uppercase;
    font-size: 33px;
    margin-bottom: 30px;
}

.liposome_choice-text{
    text-align: center;
    margin-bottom: 60px;
}

.liposome_choice-2bloc-container{
    display: flex;
    gap: 50px;
}

.liposome_choice-leftBloc-container{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    width: 50%;
    border-radius: 8px;
}

.liposome_choice-leftBloc-title{
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 30px;
}

.liposome_choice-leftBloc-img{
    width: 100%;
    height: auto;
    margin-top: 30px;
}

.liposome_choice-rightBloc-contaienr{
    width: 50%;
    background-image: url("../../assets/img/niosome_schema-bg.png");
    color: white;
    padding: 20px;
    border-radius: 8px;
}

.liposome_choice-rightBloc-title{
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 30px;
}

.liposome_choice-rightBloc-img{
    width: 100%;
    height: auto;
    margin-top: 30px;
}

.liposome_choice_bottomBloc-container{
    margin-top : 30px;
    width: 100%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    padding-bottom: 0px;
    height: 350px;
    border-radius: 8px;
}

.liposome_choice_bottomBloc-description-container{
    padding: 20px;
}

.liposome_choice_bottomBloc-description-title{
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 30px;
}

.liposome_listingMp-container{
    margin-top: 85px;
    margin-bottom: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.liposome_forme-categori-container-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.carousel_item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1450px) {
    .liposome_avantage-img{
        width: 45vw;
        height: auto;
    }
}

@media (max-width: 1350px) {
    .liposome_intro-img{
        height: 500px;
        width: auto;
    }

    .font-42{
        font-size: 37px;
    }

    .liposome_intro-title{
        font-size: 33px;
    }

    .liposome_intro-container{
        padding-top: 80px;
        gap:0px;
    }

    .liposome_forme-title{
        font-size: 23px;
    }

    .liposome_avantage-container{
        gap: 0px;
        justify-content: space-between;
        width: 100%;
    }

    .liposome_avantage-content-container{
        width: 40vw;
    }

    .liposome_avantage-img_right{
        max-width: 100%;
    }
}

@media (max-width: 1150px) {
    .liposome_choice_bottomBloc-description-text{
        font-size: 14px;
    }

    .liposome_choice-leftBloc-text{
        font-size: 14px;
    }

    .liposome_choice-rightBloc-text{
        font-size: 14px;
    }
}

@media (max-width: 1100px) {
    .liposome_avantage-img{
        width: 50vw;
    }

    .liposome_forme-categori-container{
        gap: 20px;
    }
}

@media (max-width: 1050px) {
    .liposome_intro-container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0px
    }

    .liposome_intro-title{
        padding-bottom: 0px;
        text-align: center;
    }

    .liposome_intro-img{
       width: 450px; 
    }

    .liposome_forme-icon-container{
        flex-direction: column;
        gap: 15px;
    }

    .liposome_avantage-container{
        flex-direction: column;
        align-items: center;
    }

    .liposome_avantage-img{
        width: 80%;
    }

    .liposome_avantage-content-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .liposome_avantage-title{
        text-align: center;
    }

    .liposome_avantage-buletPoint-container{
        width: 100%;
    }

    .liposome_choice-title{
        font-size: 23px;
    }

    .liposome_choice-2bloc-container{
        gap: 20px
    }

    .liposome_choice-leftBloc-title{
        font-size: 18px;
    }

    .liposome_choice-rightBloc-title{
        font-size: 18px;
    }

    .liposome_choice_bottomBloc-description-title{
        font-size: 18px;
    }

    .liposome_choice_bottomBloc-description-img{
        width: 50%;
        height: auto;
    }
}

@media (max-width: 800px) {
    .liposome_choice-2bloc-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .liposome_choice-leftBloc-container{
        padding: 0px;
        width: 100%;
        border-radius: 10px;
    }

    .liposome_choice-rightBloc-contaienr{
        padding: 0px;
        width: 100%;
        border-radius: 10px;
    }

    .liposome_choice_bottomBloc-container{
        border-radius: 10px;
        flex-direction: column;
        height: 600px;
        position: relative
    }

    .liposome_choice-leftBloc-title, .liposome_choice-rightBloc-title{
        padding-left: 30px;
        padding-top: 30px;
        padding-right: 30px;
    }

    .liposome_choice-leftBloc-text, .liposome_choice-rightBloc-text{
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }

    .liposome_choice-leftBloc-img{
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .liposome_choice_bottomBloc-description-img{
        position: absolute;
        width: 100%;
        bottom: 0px;
    }
}

@media (max-width: 540px) {
    .liposome_intro-img{
       width: 330px; 
       height: auto;
    }

    .liposome_intro-title{
        font-size: 23px;
    }

    .font-42{
        font-size: 27px;
    }

    .liposome_avantage-img{
        width: 100%;
    }
}

@media (max-width: 420px) {
    .liposome_intro-img{
       width: 270px; 
    }
}

@media (max-width: 415px) {
    .liposome_forme-title{
        text-align: center;
    }

    .liposome_forme-categori-container{
        gap: 10px;
    }
}