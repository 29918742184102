.moreinfo_container {
    position: relative;
    margin-top: 30px;
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF7F50;
    border-end-end-radius: 10px;
    border-end-start-radius: 10px;
    cursor: pointer;
}

/* Position absolue si le props `position` est vrai */
.moreinfo_container.absolute-position {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0; /* Supprimez le margin-top si en position absolue */
}

.moreinfo_text {
    position: relative;
    display: inline-block;
    color: white;
    text-transform: uppercase;
    text-align: center;
}

@media (max-width: 490px) {
    .moreinfo_text {
        font-size: 14px;
    }
}
