.categorycard_container{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 260px;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
}

.categorycard_img{
    background-size: contain;
    background-repeat: no-repeat;
    height: 90px;
    width: 115px;
    margin-top: 40px;
    margin-bottom: 20px;
}

.categorycard_title{
    text-align: center;
    text-transform: uppercase;
}

.categorycard_subtitle{
    text-transform: uppercase;
    text-align: center;
}

.width-80{
    width: 80px;
}