.probiotique_global-container{
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    font-family: 'Montserrat', sans-serif;
}

.probiotique_intro-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 70px;
    width: 100%;
}

.probiotique_intro-img{
    height: 500px;
    width: auto;
}

.probiotique_intro-title{
    font-size: 37px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.probiotique_intro_legend-title{
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.probiotique_intro-subtitle{
   text-transform: uppercase;
   font-weight: bold;
   margin-bottom: 30px;
}

.probiotique_intro-text{
    margin-bottom: 30px;
}

.probiotique_intro_probio-btn{
   border: 1px solid #FF7F50;
    background-color: transparent;
    border-radius: 8px;
    width: 250px;
    height: 50px;
    color: #FF7F50;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 40px;
    cursor: pointer;
    font-family: Montserrat;
    padding: 10px;
}

.probiotique_intro_postbio-btn{
    border: 1px solid #FF7F50;
    background-color: #FF7F50;
    border-radius: 8px;
    width: 250px;
    height: 50px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 40px;
    cursor: pointer;
    font-family: Montserrat;
    padding: 10px;
}

.probiotique_positifPoint-container-bg{
    margin-top: 85px;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-image: url('../../assets/img/positifPoint-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
}

.probiotique_positifPoint-container{
    display: flex;
    align-items: center;
    max-width: 1350px;
    gap: 100px;
    padding: 30px;
}

.probiotique_positifPoint-title{
    text-transform: uppercase;
    font-size: 33px;
    margin-bottom: 30px;
}

.probiotique_positifPoint_content-container{
    max-width: 650px;
}

.probiotique_tech-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 85px;
}

.probiotique_tech-title{
    font-size: 33px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
}

.probiotique_tech_content-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.probiotique_tech-text{
    max-width: 33%;
}

.probiotique_tech-img{
    width: 25%;
    height: auto;
}

.probiotique_tech_checkList-container{
    display: flex;
    flex-direction: column;
}

.probiotique_tech_checkList-item{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    gap: 20px;
}

.probiotique_tech_checkList-icon-container{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    background-color: #F8F8F8;
    border-radius: 5px;
}

.probiotique_tech_checkList-icon{
    color: #FF7F50;
    font-size: 20px;
}

.probiotique_tech_checkList-text{
    color:#FF7F50;
    font-weight: bold
}

.postbiotique_positifPoint-container-bg{
    margin-top: 85px;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-image: url('../../assets/img/positifPoint-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    padding-left: 0;
    padding-right: 0;
}

.postbiotique_tech-container{
    margin-top: 85px;
}

.probiotique_listingMp-container, .postbiotique_listingMp-container{
    margin-top: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.probiotique_positifPoint_content-container{
    width: 70%;
}

.probiotique_choice_global-container{
    margin-top: 85px;
}

@media (max-width: 1350px) {
    .probiotique_positifPoint-container{
        gap: 30px;
        padding: 50px;
    }
}

@media (max-width: 1100px) {
    .probiotique_positifPoint-container{
        flex-direction: column;
        align-items: normal;
    }

    .probiotique_positifPoint_content-container{
        display: flex;
        flex-direction: column;
        min-width: 100%;
    }

    .probiotique_positifPoint-title{
        font-size: 25px;
    }
}

@media (max-width: 1050px) {
    .probiotique_intro-container{
        flex-direction: column;
    }

    .probiotique_intro-title{
        font-size: 33px;
        width: 100%;
        text-align: center;
    }

    .probiotique_intro-subtitle{
        margin-top: 20px;
        text-align: center;
    }

    .probiotique_intro-text{
        text-align: center;
    }

    .probiotique_intro-img{
        height: 400px;
    }

    .probiotique_intro_probio-btn{
        margin-bottom: 30px;
    }

    .probiotique_positifPoint-container{
        padding: 30px;
    }

    .probiotique_tech_content-container{
        flex-direction: column;
        gap: 30px;
    }

    .probiotique_tech-title{
        font-size: 23px;
    }
    
    .probiotique_tech_checkList-item{
        justify-content: left;
    }

    .probiotique_tech-text{
        max-width: none;
        width: 80%;
    }

    .probiotique_tech_checkList-container{
        width: 80%;
    }
    
    .probiotique_tech-img{
        width: 50%;
        max-width: 300px;
    }
}

@media (max-width: 540px) {
    .probiotique_intro-title{
        font-size: 23px;
    }

    .probiotique_intro_legend-title{
        font-size: 14px;
    }

    .probiotique_intro-img{
        max-width: 80vw;
        height: auto;
    }

    .probiotique_positifPoint-title{
        font-size: 23px;
    }

    .probiotique_tech-title{
        width: 100%;
    }

    .probiotique_tech-text{
        width: 100%;
    }

    .probiotique_tech_checkList-container{
        width: 100%;
    }
}