.formulacard_container_content{
    height: 720px;
    width: 570px;
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 70px 20px 50px;
    position: relative;
}

.formulacard_img{
    width: auto;
    object-fit: cover;
    display: block;
    height: 105px;
}

.formulacard_title{
    padding-top: 20px;
    text-transform: uppercase;
    font-weight: 500;
}

.formulacard_subtitle{
    text-align: center;
    padding: 35px 0px 35px 0px;
}

.formulacard_list{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
}

.formulacard_list_item{
    list-style: none;
}

.formulacard_orange{
    color: #FF7F50;
    text-transform: uppercase;
}

.formulaCard_info{
    display: flex;
    justify-content: center;
    align-items: center;
}

.formulacard_icon_check{
    height: 25px;
    width: 25px;
}

.formulacard_list_icon{
    display: flex;
    gap: 10px;
}

.formulacard_orange_title{
    color: #FF7F50;
}

@media (max-width: 1500px) {
    .formulacard_container_content{
        width: 400px;
        height: 810px;
        padding: 35px 55px 5px 35px;
    }

    .formulacard_title{
        font-size: 20px;
    }

    .formulacard_img{
        height: 80px;
    }
}

@media (max-width: 630px) {
    .formulacard_list_item{
        font-size: 14px;
    }

    .formulacard_subtitle{
        font-size: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .formulacard_container_content{
        width: 380px;
        height: 700px;
        padding: 20px 40px 0px 20px;
    }
}

@media (max-width: 490px) {
    .formulacard_list_item{
        font-size: 12px;
    }

    .formulacard_subtitle{
        font-size: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .formulacard_container_content{
        width: 250px;
        height: 720px;
        padding: 20px 40px 20px 20px;
    }

    .formulacard_title{
        text-align: center;
    }
}