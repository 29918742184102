.formulaBox-container{
    display: flex;
    align-items: center;
    justify-content: top;
    flex-direction: column;
    height: 470px;
    width: 440px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 60px;
    position: relative;
}

.formulaBox-img{
   margin-bottom: 20px; 
   height: 105px;
}

.formulabox_title{
    font-size: 27px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: center;
}

.horizontal-bar{
    width: 150px;
    height: 1.5px;
    background-color: black;
    margin-bottom: 40px;
}

@media (max-width: 1225px) {
    .formulaBox-container{
        height: 550px;
        width: 420px;
    }
}

@media (max-width: 1050px) {
    .formulaBox-container{
        height: 470px;
        width: 500px;
    }

    .formulaBox-container{
        font-size: 15px;
    }
}

@media (max-width: 660px) {
    .formulaBox-container{
        height: 470px;
        width: 400px;
    }

    .formulabox_title{
        font-size: 20px;
    }
}

@media (max-width: 550px) {
    .formulaBox-container{
        height: 500px;
        width: 350px;
    }
}

@media (max-width: 480px) {
    .formulaBox-container{
        height: 570px;
        width: 300px;
    }
}

@media (max-width: 405px) {
    .formulaBox-container{
        height: 570px;
        width: 270px;
    }
}

@media (max-width: 405px) {
    .formulaBox-container{
        height: 570px;
        width: 250px;
    }
}