.niosome_global-container{
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    font-family: 'Montserrat', sans-serif;
}

.niosome_intro-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 80px;
}

.niosome_intro_content-container{
    max-width: 600px;
}

.niosome_intro-title{
    margin-bottom: 30px;
    text-transform: uppercase;
    font-size: 33px;
}

.niosome_intro-subtitle{
    text-transform: uppercase;
    margin-bottom: 30px;
    font-weight: bold;
}

.niosome_intro-img{
    height: 450px;
    width: auto;
}

.niosome_gamme-container{
    margin-top: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.niosome_gamme-title{
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.niosome_gamme_icon-container{
    margin-top: 30px;
    display: flex;
    gap: 100px;
}

.niosome_gamme_icon-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.niosome_gamme-item-title{
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.niosome_gamme-item-subtitle{
    text-align: center;
}

.niosome_presentation-container{
    margin-top: 85px;
    margin-bottom: 85px;
}

.niosome_presentation-title{
    text-transform: uppercase;
    text-align: center;
    font-size: 33px;
    margin-bottom: 30px;
}

.niosome_presentation_schema-container{
    display: flex;
    margin-top: 50px;
    gap: 100px;
}

.niosome_presentation_leftSchema-container{
    display: flex;
    align-items: center;
}

.niosome_presentation_rightSchema-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.niosome_presentation_rightSchema-title{
    text-transform: uppercase;
    font-size: 27px;
    font-weight: normal;
    margin-bottom: 30px;
}

.niosome_presentation_rightSchema-img{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EBEBE9;
    border-radius: 10px;
    height: 300px;
    width: 100%;
    margin-bottom: 30px;
}

.niosome_presentation_rightSchema-text{
    margin-bottom: 20px;
}

.niosome_presentation_bulletPoint-text{
    margin-bottom: 20px;
}

.upppercase{
    text-transform: uppercase;
}

.niosome_listingMp-container{
    margin-bottom: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.niosome_choice-container-intro{
   display: flex; 
   width: 100%;
   gap: 50px;
   padding-bottom: 30px;
}

.niosome_choice-container-intro-item{
    flex: 1;
}

.niosome_choice-title-intro{
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 10px;
    width: 100%;
}

@media (max-width: 1450px) {
    .niosome_presentation_leftSchema-img{
        width: 45vw;
        height: auto;
    }

    .niosome_presentation_schema-container{
        gap: 20px;
    }
}

@media (max-width: 1350px) {
    .niosome_intro-img{
        width: 40%;
        min-width: 480px;
        height: auto;
    }

    .niosome_presentation_rightSchema-container{
        width: 40vw;
    }

    .niosome_presentation_rightchema-img{
        max-width: 100%;
    }

    .niosome_presentation_rightSchema-title{
        font-size: 23px;
    }

}

@media (max-width: 1050px) {
    .niosome_intro-container{
        flex-direction: column;
    }

    .niosome_intro-title{
        text-align: center;
    }

    .niosome_intro-subtitle{
        margin-top:20px;
    }
    
    .niosome_presentation_schema-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .niosome_presentation_rightSchema-container{
        width: 80vw;
    }

    .niosome_presentation_leftSchema-img{
        width: 80vw;
    }

    .niosome_presentation_rightSchema-title{
        text-align: center;
    }
}

@media (max-width: 900px) {
    .niosome_choice-container-intro{
        flex-direction: column;
    }
}

@media (max-width: 540px) {
    .niosome_intro-img{
        min-width: 300px;
    }

    .niosome_intro-subtitle{
        font-size: 14px;
        text-align: center;
    }

    .niosome_intro-title{
        font-size: 23px;
    }

    .niosome_presentation_rightSchema-container{
        width: 100%;
    }

    .niosome_presentation_leftSchema-img{
        width: 100%;
    }
}