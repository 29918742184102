.labo_global-container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 45px;
    font-family: 'Montserrat', sans-serif;
}

/* INTRODUCTION */

.labo_introduction_content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
    margin-top: 100px
}

.labo_introduction-img {
    height: 500px;
}

.labo_introduction-text {
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
    margin-top: 40px;
}

.labo_introduction-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 37px;
    text-transform: uppercase;
}

/* JOBS */

.labo_jobs-timline-conainter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* FORMULA */

.labo_formula-title {
    text-transform: uppercase;
    font-size: 33px;
    text-align: center;
}

/* RESSOURCE */

.labo_formula_box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding-top: 30px
}

.labo_ressource-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 85px;
    margin-bottom: 60px;
}

.labo_ressource-title {
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 15px;
}

.labo_ressource-subtitle {
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
}

.labo_ressource-imgCard-container {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    gap: 30px;
}

.labo_jobs-title{
    text-transform: uppercase ;
    text-align: left;
    width: 100%;
    margin-top: 85px;
    margin-bottom: 20px;
}

@media (max-width: 1300px) {
    .labo_introduction-img {
        height: 400px;
    }
}

@media (max-width: 1175px) {
    .labo_introduction-img {
        height: 350px;
    }

    .labo_ressource-imgCard-container {
        gap: 15px;
    }

    .labo_introduction_content {
        gap: 50px
    }

    .font-40 {
        font-size: 39px;
    }

    .labo_introduction-title {
        font-size: 34px;
    }
}

@media (max-width: 1150px) {
    .labo_ressource_carousel-img {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding-top: 10px;
    }
}

@media (max-width: 1050px) {

    .labo_introduction-img-mobile {
        height: 350px;
    }

    .labo_introduction-img {
        display: none;
    }

    .labo_formula_box-container {
        flex-direction: column;
        gap: 80px;
    }

    .labo_introduction_content-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .labo_introduction-title {
        font-size: 27px;
        text-align: center;
    }

    .font-40 {
        font-size: 27px;
    }

    .labo_introduction-text {
        font-size: 15px;
    }

    .labo_formula-title {
        font-size: 23px;
    }

    .labo_ressource-title {
        font-size: 23px;
    }

    .labo_ressource-subtitle {
        font-size: 18px;
    }
}

@media (max-width: 860px) {
    .labo_formula-container {
        padding-top: 85px;
    }

    .labo_introduction-img-mobile {
        max-width: 300px;
        height: auto;
    }
}