.fiche_global-content {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 45px;
    font-family: 'Montserrat', sans-serif;
}

.bienfait-title{
    font-size: 16px;
    font-weight: 500;
}

.fiche_description_global-content {
    padding-bottom: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.fiche-retour{
    margin-top: 100px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
}

.fiche_description-title {
    text-transform: uppercase;
    color: #FF7F50;
    font-weight: bold;
    font-size: 41px;
}

.fiche_disponibility-container{
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 370px;
    padding: 10px;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: bold;
    gap: 20px;
    margin-top: 10px;
    cursor: pointer;
}

.margin-top-70{
    margin-top: 70px;
}

.fiche_subtitle-container {
    display: flex;
    gap: 5px;
}

.fiche_description-subtitle::first-letter {
    text-transform: uppercase;
}

.fiche_description-desc {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 25px;
    margin-top: 40px;
    margin-bottom: 20px;
}

.fiche_description-img-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fiche_description-img {
    height: 400px;
}

.fiche_description-btn {
    border-radius: 10px;
    border: 2px solid #FF7F50;
    width: 250px;
    height: 40px;
    background-color: transparent;
    color: #FF7F50;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
}

.fiche_encarts-container {
    display: flex;
    flex-direction: row;
    gap: 100px;
    padding-top: 85px;
}

.fiche_bienfait-title {
    color: #FF7F50;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 400;
}

.fiche_contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 85px;
}

.fiche-contact-title {
    font-size: 35px;
    text-transform: uppercase;
    font-weight: bold;
}

.fiche_bienfait-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.fiche_description_formes-text{
    color:#FF7F50;
    text-transform: uppercase;
}

.fiche_description_formes-container{
    margin-top: 10px;
    background-color: #F8F8F8;
    border-radius: 10px;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 15px;
}

.fiche_description_formes-title{
    text-transform: uppercase;
}

.fiche_description_formes-list{
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.fiche_description_forme-item{
    display: flex;
    gap: 5px;
}

.fiche_disponibility-item{
    display: flex;
    gap: 10px;
}

@media (max-width: 1435px) {
    .fiche_encarts-container {
        gap: 50px;
    }
}

@media (max-width: 1350px) {
    .fiche_bienfait-container {
        width: 40%;
    }
}

@media (max-width: 1200px) {
    .fiche_description-img {
        width: 430px;
        height: auto;
    }
}


@media (max-width: 1050px) {
    .fiche_description-img {
        width: 410px;
        height: auto;
    }

    .fiche_description-text {
        font-size: 14px;
    }

    .fiche_encarts-container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .fiche_bienfait-container {
        width: 100%;
    }
}

@media (max-width: 900px) {
    .fiche_description-text-content--mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .fiche_description_global-content--mobile {
        margin-top: 100px;
        padding-bottom: 85px;
    }

    .fiche_description-text {
        font-size: 16px;
    }

    .fiche_description-title {
        text-align: center;
    }

    .fiche_description-btn {
        margin-top: 20px;
    }
}

@media (max-width: 600px) {
    .fiche_description_formes-container{
        width: 250px;
        justify-content: center;
    }

    .fiche_description_formes-title{
        display: none;
    }

    .fiche_description_formes-list{
        gap: 20px
    }
}

@media (max-width: 510px) {
    .fiche_description-img {
        width: 290px;
        height: auto;
    }

    .fiche-contact-title {
        font-size: 27px;
    }

    .fiche_disponibility-text{
        font-size: 12px;
    }

    .fiche_disponibility-container{
        width: 280px;
    }

    .fiche_disponibility-text{
        font-size: 12px;
    }
}

@media (max-width: 500px) {
    .fiche_description-text {
        font-size: 14px;
    }
}

@media (max-width: 410px) {
    .fiche-contact-title {
        font-size: 25px;
    }

    .fiche_disponibility-text{
        font-size: 12px;
    }

    .fiche_disponibility-container{
        width: 280px;
    }
}

@media (max-width: 390px) {
    .fiche-contact-title {
        font-size: 22px;
    }

    .fiche_disponibility-text{
        font-size: 10px;
    }

    .fiche_disponibility-container{
        width: 250px;
        gap: 10px;
        padding: 5px;
    }

    .fiche_disponibility-img{
        width: 10px;
        height: auto;
    }
}


@media (max-width: 345px) {
    .fiche-contact-title {
        font-size: 21px;
    }

    .fiche_disponibility-text{
        font-size: 10px;
    }

    .fiche_disponibility-container{
        width: 240px;
    }
}
