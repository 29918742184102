.catalogue-container {
    width: 100%;
}

.filter-item {
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.filter-item:hover {
    background-color: #eee;
}

.filter-item.active {
    background-color: #FF7F50;
    color: white;
}

.table-container {
    overflow-x: auto;
}

.tc-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.tc-recherche {
    display: flex;
    align-items: center;
}

.tc-p-recherche {
    width: 100%;
    font-size: 16px;
}

.search-bar {
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 12px;
    background-color: #ebebe9;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.search-bar:focus {
    outline: none;
    border-color: #5b9bd5;
}

.table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
}

.table thead {
    background-color: #f3f4f6;
}

.table th,
.table td {
    padding: 10px;

}

.th {
    text-transform: uppercase;
}

.td::first-letter {
    text-transform: uppercase;
}

.left {
    text-align: left;
}

.flex{
    display: flex;
    gap: 5px;
}

.center {
    text-align: center;
    vertical-align: middle;
}

.table th {
    color: #FF7F50;
}

.table tbody tr:nth-child(odd) {
    background-color: #ffffff;
}

.table tbody tr:nth-child(even) {
    background-color: #f3f3f3;
}

.table tbody tr:hover {
    background-color: #e0e0e0;
}

.tr:hover {
    color: #FF7F50;
    cursor: pointer;
}

.tc-pastrouver {
    margin: 20px;
}

.tc-pastrouver-h3 {
    margin-bottom: 10px;
}

.tc-pastrouver-p {
    font-size: 14px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-span {
    color: #FF7F50;
    font-weight: bold;
    margin: 20px;
}

.pagination-btn {
    color: #FF7F50;
    border: none;
    border-radius: 5px;
    height: 30px;
    font-weight: bold;
    padding: 5px;
}

.pagination-btn:hover {
    color: #ebebe9;
    background-color: #FF7F50;
    border: none;
    border-radius: 5px;
    height: 30px;
    font-weight: bold;
    padding: 5px;
}

.forme-icon {
    margin-left: 5px;
    margin-right: 5px;
}

.catalogue_filtre-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.catalogue_filtre_forme-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.catalogue_filtre_forme-container {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;  
    padding: 8px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.catalogue_filtre_forme-container:hover {
    background-color: rgba(255, 165, 0, 0.2); 
}

.catalogue_filtre_forme-container.active {
    background-color: rgba(255, 165, 0, 0.4); 
    box-shadow: 0px 4px 8px rgba(255, 165, 0, 0.3);
}

.catalogue_filtre_forme-img {
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease-in-out;
}

.catalogue_filtre_forme-container:hover .catalogue_filtre_forme-img {
    transform: scale(1.1);
}

@media (max-width: 1050px) {
    .none{
        display: none;
    }
}

@media (max-width: 630px) {
    .tc-head {
        flex-direction: column;
        gap: 20px;
    }

    .table th,
    .table td {
        font-size: 12px;
    }

    .forme-icon {
        margin-left: 0px;
        margin-right: 5px;
        height: 15px;
        width: auto;
    }
}

@media (max-width: 400px) {
    .table th,
    .table td  {
        font-size: 10px;
    }
}