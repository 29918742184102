.conditionnement_global-container{

}

.conditionnement_img-container{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 5px;
}

.conditionnement-title{
    text-transform: uppercase;
}

.conditionnement-allSize{
    font-size: 12px;
    font-weight: bold;
}

.conditionnement-content{
    max-width: 250px;
}