.mockupTab_global-container{
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.mockupTab_category-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 300px
}

.mockupTab_filtre_element-container{
    display: flex;
    flex-direction: column;
}

.mockupTab_filtre-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.ProductCardMockup-img{
    width: 180px;
    height: 263px;
}

.mockupTab_element-container{
    width: 1000px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}   

.mockupTab_category-title{
    text-transform: uppercase;
    color: #FF7F50;
    line-height: 25px;
}

.orange-line{
    height: 3px;
    width: 150px;
    background-color: #FF7F50;
}

.mockupTab_category-item{
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
}

.selected{
    font-weight: bold;
    font-size: 16px;
}

.mockupTab_filtre-label{
    font-weight: 500;
    text-transform: lowercase;
}

.mockupTab_filtre-label::first-letter{
    text-transform: uppercase;
}

.mockupTab_search-bar{
    margin-right: 4px;
}

.mockupTab_search-input{
    background-color: #EBEBE9;
    border: none;
    border-radius: 8px;
    width: 150px;
    height: 30px;
}

.mockupTab_search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.mockupTab_search-icon {
    position: absolute;
    left: 10px;
    width: 16px;
    height: 16px;
    opacity: 0.5;
}

.mockupTab_search-input {
    width: 100%;
    padding: 8px 10px 8px 30px;
    border: none;
    background: transparent;
    outline: none;
    margin-left: 10px;
}
