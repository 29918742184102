.imageCard_overlay{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.imageCard_hover-text{
    color: #FF7F50;
    font-size: 27px;
}

.imageCard_title{
    font-size: 27px;
    text-transform: uppercase;
    margin-top: 100px;
    margin-bottom: 50px;
}


.imageCard_global-container {
    position: relative;
    height: 540px;
    width: 400px;
    border-radius: 10px;
    border-radius: 30px;
    overflow: hidden;
    cursor: pointer;
}

/* Couche de fond */
.imageCard_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: filter 0.3s ease-in-out;
    z-index: 1;
}

/* Appliquer l'assombrissement au survol */
.imageCard_background.hovered {
    filter: brightness(50%);
}

/* Couche pour le contenu texte */
.imageCard_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    pointer-events: none;
}

/* Texte affiché au survol */
.imageCard_hover-text {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    pointer-events: none;
}

.imageCard_content{
    color: white;
    font-weight: 100;
    text-align: center;
}

.content_group{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.size-23{
    font-size: 23px;
}

@media (max-width: 1320px) {
    .imageCard_global-container{
        height: 500px;
        width: 380px;
    }
}

@media (max-width: 1270px) {
    .imageCard_global-container{
        height: 450px;
        width: 370px;
    }
}

@media (max-width: 1210px) {
    .imageCard_global-container{
        height: 450px;
        width: 350px;
    }
}

@media (max-width: 1150px) {
    .imageCard_background{
        margin-top: 60px;
    }
}