.dropdown {
    position: relative;
}

.dropdown_menu, .dropdown_menu2 {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px 0;
    list-style: none;
    display: block;
    z-index: 10;
    width: 200px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.dropdown_menu{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.dropdown_menu2 {
    width: 230px;
}

.dropdown_item {
    padding: 10px 15px;
    font-size: 14px;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dropdown_item:hover {
    background-color: #f5f5f5;
}

@media (max-width: 1380px) {
    .dropdown_menu2 {
        width: 190px;
        font-size: 12px;
    }

    .dropdown_menu {
        width: 170px;
        font-size: 12px;
    }
}