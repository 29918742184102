.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
}

.popup-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.popup-title{
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 20px;
}

.popup-container-2input{
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.popup-label{
    font-size: 12px;
    font-weight: 500;
    text-align: left;
}

.popup-subtitle{
    font-size: 12px;
    text-align: left;
}

.popup-textarea{
    width: 100%;
}

.popup-input1, .popup-input2 {
    background-color: #EBEBE9;
    border-radius: 5px;
    border: none;
    height: 30px;
}

.popup-input1{
    width: 240px;
}

.popup-input2{
    width: 150px;
}

.popup-textarea{
    background-color: #EBEBE9;
    border-radius: 5px;
    border: none;
    height: 45px;
    margin-bottom: 10px;
}

.popup-btn{
    border: none;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    cursor: pointer;
}