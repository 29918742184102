.global-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8F8F8;
    height: 100px;
    padding: 50px;
}

.container-content-footer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 40px;
    max-width: 1350px;
}

.container-text-footer{
    color: rgb(163, 163, 163);
    display: flex;
    flex-direction: column;
}

.text-footer{
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    border: none;
    background-color: transparent;
    text-align: justify;
    font-size: 16px;
    color: #6D686980;
}

.container-footer{
    display: flex;
    justify-content: center;
}

.icon-footer{
    height: 100px;
    cursor: pointer;
}

.container-adresse-footer{
    display: flex;
    flex-direction: column;
    align-items: end;
}

.black{
    color: black;
}

.clickable{
    cursor: pointer;
}


@media (max-width: 1220px) {
    .icon-footer{
        height: 70px;
    }

    .text-footer{
        font-size: 14px;
    }

    .global-footer{
        padding-left: 35px;
        padding-right: 35px;
        height: 70px;
    }
}

@media (max-width: 1050px) {

    .global-footer{
        height: 320px;
    }

    .container-content-footer{
        flex-direction: column;
        gap: 20px;
    }

    .container-adresse-footer{
        align-items: center;
    }

    .text-footer{
        text-align: center;
    }

    .icon-footer{
        height: 80px;
    }
}