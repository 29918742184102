.formule_global-container{
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 45px;
    font-family: 'Montserrat', sans-serif;
}

.formule_intro-container-global{
    display: flex;
    flex-direction: row;
    margin-top: 130px;
    gap: 30px;
    align-items: center;
    max-width: 1350px;
}

.formule_intro-container{
    display: flex;
    flex-direction: column;
}

.formule_intro-container-img{
    width: 50%;
    height: auto;
}

.formule_intro-container_title{
    min-width: 600px;
}

.formule_intro-title{
    font-size: 37px;
    font-weight: bold;
    text-transform: uppercase;
}

.formule_font-50{
    font-size: 50px;
}

.formule_intro-subtitle{
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 30px;
    margin-top: 30px;
}

.formule_ourFormule-container{
    margin-top: 85px;
}

.formule_ourFormule-title{
    font-size: 33px;
    text-align: center;
    text-transform: uppercase;
}

.formule_ourFormule-bloc-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1350px;
    justify-content: space-between;
    margin-top: 30px;
}

.formule_ourFormule-text-container{
    height: 480px;
    width: 460px;
    background-color: #F8F8F8;
    border-radius: 20px;
    padding: 40px;
    padding-bottom: 0px;
    position: relative;
}

.formule_ourFormule-plus{
    color: #FF7F50;
    font-size: 200px;
    font-weight: bold;
}

.formule_ourFormule-title-bloc{
    font-size: 25px;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.formule_ourFormule-img {
    width: 350px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.fomrule_positifPoint-container{
    margin-top: 85px;
    display: flex;
    justify-content: center;
    gap: 100px;
    width: 100vw;
    background-image: url('../../assets//img/positifPoint-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
    padding-left: 0px;
    padding-right: 0px;
}

.fomrule_positifPoint-container2{
    display: flex;
    gap: 100px;
    max-width: 1350px;
    padding: 30px;
}

.formule_positifPoint-text-container{
    width: 50%;
}

.formule_positifPoint-title{
    font-size: 33px;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.vertical-line{
    margin-top: 30px;
    height: 75px;
    width: 2px;
    background-color: black;
    margin-bottom: 30px;
}

.formule_otherCategory-title{
    text-transform: uppercase;
    font-size: 33px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
}


/* PROPRE A SUR MESURE */
.formule_intro-subtitle2{
    font-weight: bold;
    margin-bottom: 30px;
}

.formule_galenique-container{
    margin-top: 85px;
}

.formule_galenique-title{
    font-size: 33px;
    text-transform: uppercase;
    text-align: center;
}

.formule_galenique-gridContainer{
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-items: center;
}

.formule_categoryCard-container{
    margin-top: 85px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formule_categoryCard-title{
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
}

.formule_demandeMp_global-container{
    margin-top: 85px;
    height: 370px;
    width: 1350px;
    background-image: url('../../assets/img/demande-mp-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
}

.formule_demandeMp-title{
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.formule_demandeMp-btn{
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 45px;
    cursor: pointer;
}

.formule_demandeMp-horizontalBar{
    height: 1px;
    width: 155px;
    background-color: black;
    margin-top: 10px;
}

.formule_process_global-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formule_process-title{
    font-size: 33px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.formule_process-subtitle{
    text-align: center;
    margin-bottom: 50px;
}

.formule_process_comp-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    justify-items: center;
}

.formule_process_comp-container:nth-child(4),
.formule_process_comp-container:nth-child(6) {
    grid-template-columns: repeat(2, 1fr);
}

.formule_process_comp-container:has(:nth-child(6)) {
    grid-template-columns: repeat(3, 1fr);
}

.formule_process_comp-container2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 20px;
    justify-items: center;
    align-items: center;
    width: 100%;
}

.formule_product_catalogue-container{
    margin-top: 85px;
    height: 370px;
    width: 1350px;
    background-image: url('../../assets/img/formule_catalogue-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
    align-items: center;
}

.formule_product_global-container{
    margin-top: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formule_tabMockup-title{
    font-size: 33px;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.formule_product-title{
    text-transform: uppercase;
    text-align: center;
    font-size: 33px;
}

.formule_product_catalogue-title{
    text-transform: uppercase;
    font-size: 33px;
    text-align: center;
}

.formule_product_catalogue-btn{
    border-radius: 8px;
    height: 70px;
    width: 250px;
    border: 1px solid #FF7F50;
    color: #FF7F50;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 40px;
    cursor: pointer;
}


.formule_otherProduct_global-container{
    margin-top: 85px;
}

.formule_arrow_container--mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 15px;
}

.carousel{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

@media (max-width: 1400px) {
    .formule_ourFormule-bloc-container{
        justify-content: center;
        gap: 30px;
    }

    .formule_ourFormule-text-container{
        height: 440px;
        width: 380px;
    }

    .formule_ourFormule-img{
        width: 230px;
    }

    .formule_product_catalogue-container{
        width: 80vw;
        padding: 10px;
    }

    .formule_demandeMp_global-container{
        width: 100%;
    }

    .formule_demandeMp-title, .formule_demandeMp-text, .formule_demandeMp-btn{
        padding-left: 10px;
        padding-right: 10px;
    }

    .formule_demandeMp-horizontalBar{
        margin-left: 20px;
    }
}

@media (max-width: 1350px) {
    .formule_intro-container{
        gap: 0px;
    }
}

@media (max-width: 1300px) {
    .formule_ourFormule-plus{
        font-size: 140px;
    }

    .fomrule_positifPoint-container2{
        gap: 30px;
    }
}

@media (max-width: 1150px) {
    .formule_font-50{
        font-size: 42px;
    }

    .formule_intro-title{
        font-size: 33px;
    }

    .formule_intro-subtitle{
        font-size: 27px;
    }

    .formule_intro-text{
        font-size: 14px;
    }
}

@media (max-width: 1100px) {
    .formule_ourFormule-bloc-container{
        flex-direction: column;
        width: 100%;
        max-width: 85vw;
    }

    .formule_ourFormule-title{
        font-size: 23px;
    }

    .formule_positifPoint-title{
        font-size: 25px;
    }

    .fomrule_positifPoint-container2{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .formule_positifPoint-text-container{
        width: 100%
    }

    .formule_positifPoint-container{
        width: 100%;
    }
}

@media (max-width: 1050px) {
    .formule_intro-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
    

    .formule_intro-subtitle{
        margin-top: 20px;
    }

    .formule_intro-subtitle2{
        margin-top: 20px;
    }

    .formule_galenique-gridContainer {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
    }
}

@media (max-width: 900px) {

    .formule_tabMockup-title{
        font-size: 23px;
    }

    .formule_process-title{
        font-size: 23px;
    }

    .formule_intro-title{
        font-size: 23px;
    }

    .formule_font-50{
        font-size: 27px;
    }

    .formule_intro-subtitle{
        font-size: 18px;
    }

    .formule_otherCategory-title{
        font-size: 27px;
    }

    .formule_ourFormule-title-bloc{
        font-size: 20px;
    }

    .formule_product_catalogue-title{
        font-size: 23px;
    }

    .formule_galenique-title{
        font-size: 23px;
    }

    .formule_categoryCard-title{
        font-size: 23px;
    }

    .formule_demandeMp-title{
        font-size: 23px;
    }
}

@media (max-width: 510px) {

    .formule_ourFormule-text-container{
        width: 320px;
        padding: 20px;
    }

    .formule_ourFormule-img{
        width: 200px;
    }

    .formule_ourFormule-title{
        font-size: 18px;
    }
}

@media (max-width: 440px) {

    .formule_ourFormule-text-container{
        width: 280px;
        height: 450px;
    }

    .formule_otherCategory-title{
        font-size: 25px;
    }
}

@media (max-width: 405px) {
    .formule_otherCategory-title{
        font-size: 22px;
    }
}

@media (max-width: 395px) {
    .formule_ourFormule-text-container{
        width: 240px;
        height: 480px;
    }
}

@media (max-width: 370px) {
    .formule_otherCategory-title{
        font-size: 20px;
    }
}

/* //A RETIRER */
@media (max-width: 1320px) {
    .formule_tabMockup_global-container {
        display: none;
    }
}