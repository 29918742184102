body {
    overflow-x: hidden;
}

.homepage_global-container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    font-family: 'Montserrat', sans-serif;
}

/* CSS pour les élements d'intro */

.homepage_content_intro_img {
    position: relative;
    width: 100vw;
    max-height: 1080px;
    height: 85vh;
    overflow: hidden;
}

.homepage_video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* CSS pour les élements textuel, le contenue de la page */

.homepage_introduction_content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
}

.homepage_title {
    font-size: 37px;
    line-height: 1.4;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    max-width: 100%;
    width: 100%;
    word-wrap: break-word;
    text-align: justify;
    margin: 0 auto;
}


.homepage_intruction_text {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
    max-width: 530px;
    line-height: 20px;
}

.homepage_intruction_text ul {
    margin-top: 10px;
    padding-left: 20px;
}

.homepage_intruction_text ul li {
    margin-bottom: 10px;
    line-height: 1.6;
}


.orange-color {
    color: #FF7F50;
}

/* CSS pour le second contenue de la page */

.homepage_container_subtitle{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 85px;
}

.homepage_subtitle_h2{
    font-size: 33px;
    text-transform: uppercase;
}

.homepage_description_subtitle{
    text-transform: uppercase;
    font-weight: 500;
}

.homepage_subtitle_content{
    padding-top: 30px;
    max-width: 820px;
    text-align: justify;
}

.homepage_services{
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 45px;
}

.homepage_service_item{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homepage_service_icon{
    width: 90px;
    height: 90px;
}

.homepage_service_text{
    text-align: center;
    font-weight: bold;
    margin-top: 20px
}

/* CSS pour les categories */

.homepage_category_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 85px;
}

.homepage_category_title{
    text-transform: uppercase;
    font-size: 33px;
    text-align: center;
}

.homepage_category_navigation{
    display: flex;
}

.homepage_nav_categorycard{
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
}

.carousel_container_arrow{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    margin-top: 15px;
}

.carousel_arrow{
    cursor: pointer;
    border: none;
    background-color: transparent;
}

/* CSS pour les formules */

.homepage_formule_container{
    padding-top: 85px;
}

.homepage_formule_title{
    text-transform: uppercase;
    font-size: 33px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.homepage_formule_bg{
    margin-top: 30px;
    width: 100vw;
    height: 900px;
    background-image: url('../../assets/img/homepage_bg_formule.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homepage_formula_container_card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 50px
}

.homepage_formule_container_btn_mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 25px;
    padding-top: 15px;
}

.homepage_formule_btn_mobile{
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #FF7F50;
    border: 2px solid #FF7F50;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
    background-color: #fff;
}

.other-version{
    background-color: white;
    color : #FF7F50;
}

.homepage_formule_btn_mobile.active {
    background-color: #FF7F50;
    color: #fff;
}

.homepage_formule_btn_mobile:hover {
    background-color: #FF7F50;
    color: #fff;
}


/* CSS pour la partie qualité */

.homepage_qualite_container{
    padding-top: 85px;
}

.homepage_qualite_title{
    text-transform: uppercase;
    text-align: center;
    font-size: 33px;

}

.homepage_qualite_text{
    max-width: 800px;
    padding-top: 30px;
    text-align: justify;
}

.homepage_icon_container{
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 45px;
}

/* CSS pour la partie atouts */
.homepage_atouts_container{
    padding-top: 85px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
}

.homepage_atouts_title{
    text-transform: uppercase;
    font-size: 30px;
}

.homepage_atouts_box{
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
}

/* GLOBAL */

.font-40{
    font-size: 42px;
}

.bold{
    font-weight: bold;
}

.padding-bottom{
    line-height: 100px;
}

@media (max-width: 1350px) {
    .homepage_title{
        font-size: 34px;
    }
}

@media (max-width: 1200px) {
    .homepage_title{
        font-size: 30px;
    }

    .homepage_intruction_text{
        font-size: 14px;
    }

    .homepage_introduction_content{
        gap: 15px;
    }

    .homepage_formula_container_card{
        gap: 15px
    }
}

@media (max-width: 1050px) {

    .homepage_title {
        line-height: 38px;
        min-width: none;
        font-size: 23px;
        width: 530px;
    }

    .homepage_intruction_text{
        font-size: 15px;
    }

    .homepage_introduction_content{
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    /* SERVICE */
    
    .homepage_container_subtitle{
        max-width: 530px;
    }

    .homepage_subtitle_h2{
        font-size: 23px;
    }

    .homepage_subtitle_content{
        font-size: 15px;
    }

    .homepage_services {
        padding-top: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }

    .homepage_service_text {
        font-size: 14px;
    }

    .homepage_service_icon {
        height: 90px;
        width: 90px;
    }

    .homepage_service_item{
        width: 180px;
    }

    /* CATEGORY */

    .homepage_category_container{
        max-width: 530px;
    }

    .homepage_category_title{
        font-size: 23px;
        padding-bottom: 30px;
    }

    /* FORMULA */
    .homepage_formule_title{
        font-size: 23px;
    }

    /* CERTIFICATION */
    .homepage_qualite_container{
        max-width: 530px;
        font-size: 15px;
    }

    .homepage_qualite_title{
        font-size: 23px;
    }

    .homepage_icon_container{
        gap: 20px;
    }

    /* ATOUTS */
    .carousel_container{
        display: flex;
        flex-direction: row;
        gap: 1vw;
    }

    .homepage_atouts_title{
        font-size: 23px;
    }
}

@media (max-width: 460px) {

    .homepage_content_intro_img{
        height: 70vh;
    }

    .font-40{
        font-size: 27px;
    }

    .homepage_services {
        padding-top: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }

    .homepage_service_item{
        width: 150px;
    }

    .homepage_service_text {
        font-size: 12px;
    }

    .homepage_atouts_title{
        font-size: 23px;
    }
}

@media (max-width: 370px) {

    .font-{
        font-size: 25px;
    }

    .homepage_title{
        font-size: 21px;
    }

    .homepage_subtitle_h2{
        font-size: 21px;
    }

    .homepage_category_title{
        font-size: 21px;
    }

    .homepage_formule_title{
        font-size: 21px;
    }

    .homepage_qualite_title{
        font-size: 21px;
    }

    .homepage_atouts_title{
        font-size: 21px;
    }
}