.ProductCardMockup_global-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 230px;
    height: 420px;
}

.ProductCardMockup-img{
    margin-top: 30px;
}

.ProductCardMockup-title{
    text-transform: uppercase;
    color: #FF7F50;
    margin-top: 20px;
    text-align: center;
}

.ProductCardMockup_icon_subtitle-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.ProductCardMockup_icon{

}

.ProductCardMockup-subtitle{
    font-size: 12px;
}

.ProductCardMockup-btn{
    border: none;
    background-color: #FF7F50;
    color: white;
    text-transform: uppercase;
    width: 100%;
    min-height: 40px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-top: 30px;
    cursor: pointer;
}