.poisitifPoint_check-container{
   width: 30px;
   height: 30px;
   background-color: white;
   border-radius: 5px; 
   display: flex;
   justify-content: center;
   align-items: center;
}

.positifPoint_content-container{
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
}

.positifPoint-title{
    text-transform: uppercase;
}