.bienfait-title {
    margin-top: 15px;
}

.bienfaits_title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 530px;
    width: 100%;
}

.bienfaits-img-dropdown {
    transition: transform 0.3s ease-in-out;
    margin-top: 15px;
}

.bienfaits-img-dropdown.rotate {
    transform: rotate(180deg);
}

.bienfait-description{
    max-width: 550px;
}

@media (max-width: 1050px) {
    .bienfaits_title-container {
        width: 100%;
    }

    .bienfait-description{
        max-width: none;
    }
}