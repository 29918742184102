.process_global-container{
    height: 240px;
    width: 390px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 30px;
}

.process-img{
    width: 50px;
    height: auto;
}

.process-title{
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 15px;
}

.font-size25{
    font-size: 25px;
}

@media (max-width: 550px) {
    .process_global-container{
        padding: 10px;
        width: 70vw;
        height: 280px;
    }
}

@media (max-width: 400px) {
    .process_global-container{
        height: 300px;
    }
}