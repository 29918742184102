.technoBanner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: 375px;
    background-color: #F8F8F8;
    border-radius: 10px;
    padding: 10px;
    gap: 20px
}  

.technoBanner-title{
    font-size: 22px;
    margin-bottom: 20px;
}

.technoBanner-img{
    height: "235px";
    width: "326px";
    border-radius: 10px;
}

.technoBanner-text-niosome{
    font-size: 13px
}

/* PARTIE NIOSOME */

.technoBanner-container-niosome{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: 450px;
    background-color: #F8F8F8;
    border-radius: 10px;
    padding: 10px;
    gap: 20px
}  

.technoBanner-title-niosome{
    font-size: 22px;
    margin-bottom: 20px;
}

.technoBanner-img-niosome{
    height: "235px";
    width: "326px";
    border-radius: 10px;
}

@media (max-width: 1350px) {

    .technoBanner-container{
        width: 630px;
        max-width: 630px;
    }

    .technoBanner-container-niosome{
        width: 630px;
        max-width: 630px;
    }

    .technoBanner-img {
        width: 230px;
    }

    .technoBanner-img-niosome {
        width: 200px;
    }
}

@media (max-width: 1200px) {

    .technoBanner-title{
        font-size: 20px;
    }

    .technoBanner-img{
        width: 200px;
    }

    .technoBanner-img-niosome{
        width: 190px;
    }

    .technoBanner-container{
        max-width: 550px;
    }

    .technoBanner-container-niosome{
        max-width: 550px;
    }

    .technoBanner-text-niosome{
        font-size: 14px;
    }
}

@media (max-width: 1050px) {

    .technoBanner-container{
        margin-top: 60px ;
    }

    .technoBanner-container-niosome{
        margin-top: 60px ;
    }
}

@media (max-width: 610px) {
    .technoBanner-container{
        width: 500px;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .technoBanner-container-niosome{
        width: 500px;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .technoBanner-img-niosome{
        width: 130px;
    }
}

@media (max-width: 550px) {
    .technoBanner-container-niosome{
        min-height: 500px;
    }

    .technoBanner-img-niosome{
        width: 110px;
    }
}

@media (max-width: 430px) {
    .technoBanner-title{
        font-size: 18px;
    }

    .technoBanner-img{
        width: 150px;
    }

    .technoBanner-container-niosome{
        min-height: 520px;
    }

    .technoBanner-img-niosome{
        width: 90px;
    }
}

@media (max-width: 410px) {
    .technoBanner-container{
        width: 500px;
        text-align: center;
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

@media (max-width: 390px) {
    .technoBanner-container-niosome{
        min-height: 600px;
    }

    .technoBanner-img-niosome{
        width: 80px;
    }
}


