.mockupBanner_global-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #F8F8F8;
    width: 580px;
    height: 350px;
    gap: 40px;
    margin-top: 60px;
}

.mockupBanner-img {
    width: 210px;
    height: 290px;
}

.mockupBanner-subtitle {
    font-weight: 700;
    padding-bottom: 15px;
}

.mockupBanner-title {
    font-size: 22px;
    color: #FF7F50;
    font-weight: 500;
    margin-top: 30px;
    text-transform: uppercase;
}

.mockupBanner-horizontal-bar {
    width: 60px;
    height: 1px;
    background-color: black;
}

.mockupBanner-subtitle::first-letter {
    text-transform: uppercase;
}

.mockupBanner-text {
    font-size: 20px;
    text-transform: uppercase;
}


/* VERSION MOBILE 475px*/
.mockupBanner_mobile_global-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mockupBanner_mobile-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

@media (max-width: 560px) {
    .mockupBanner-img {
        width: 180px;
        height: auto;
    }
}

@media (max-width: 515px) {
    .mockupBanner_global-container {
        gap: 20px;

    }

    .mockupBanner-title {
        font-size: 20px;
    }

    .mockupBanner-text {
        font-size: 18px;
    }
}

@media (max-width: 475px) {
    .mockupBanner_global-container {
        height: 400px;
    }

    .mockupBanner-title {
        font-size: 18px;
    }

    .mockupBanner-text {
        font-size: 16px;
    }
}

@media (max-width: 460px) {
    .mockupBanner_global-container {
        height: 350px;
    }

    .mockupBanner-img{
        width: 140px;
    }
}

@media (max-width: 420px) {

    .mockupBanner_global-container {
        height: 310px;
    }

    .mockupBanner-img{
        width: 110px;
    }
}

@media (max-width: 420px) {

    .mockupBanner_mobile-container {
        gap: 10px;
    }

    .mockupBanner-img{
        width: 110px;
    }

    .mockupBanner-title {
        font-size: 16px;
    }

    .mockupBanner-text {
        font-size: 14px;
    }

    .mockupBanner-subtitle{
        font-size: 14px;
    }
}

@media (max-width: 350px) {

    .mockupBanner-img{
        width: 80px;
    }
}